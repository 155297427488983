import { Skeleton, Switch, SwitchProps } from '@mui/material';
import { ChangeEvent, useState } from 'react';

interface LoadingSwitchProps extends Omit<SwitchProps, 'onChange'> {
  readonly loading?: boolean;
  readonly onChange: (value: boolean) => any;
}

export const LoadingSwitch = ({ loading, onChange, ...props }: LoadingSwitchProps) => {
  const [loadingInternal, setLoadingInternal] = useState(false);

  const showLoading = loading || loadingInternal;

  const handleChange = async (_event: ChangeEvent, value: boolean) => {
    setLoadingInternal(true);
    try {
      await onChange(value);
    } finally {
      setLoadingInternal(false);
    }
  };

  if (showLoading) {
    // TODO we need a design for this
    return <Skeleton variant="rounded" width={36} height={20} />;
  } else {
    return <Switch {...props} onChange={handleChange} />;
  }
};
